export function goToLogin(brandDomain: string) {
  if (
    import.meta.env.VITE_TARGET_ENV &&
    !['production'].includes(import.meta.env.VITE_TARGET_ENV)
  ) {
    const loginPath = `${window.env.PATH_PREFIX}/login`;
    if (window.location.pathname === loginPath) {
      return;
    }
    window.location.href = loginPath;
    return;
  }
  window.open(`${brandDomain}/login`, '_self');
}
