import { useState, useEffect } from 'react';
import { useAccount, useSession } from '@ltvco/refresh-lib/v1';

export function useLimitedPlanInfo() {
  const {
    session: { isAuthenticated },
  } = useSession();
  const { data: account, isLoading } = useAccount(isAuthenticated);

  /** Flag to indicate if the account data has been received and loaded */
  const [accountLoaded, setAccountLoaded] = useState(false);

  // *****************************************************
  // Subscription Info
  // *****************************************************

  const [planAmount, setPlanAmount] = useState(0);
  const [planMonthlyCost, setPlanMonthlyCost] = useState(0);
  const [renewalPeriod, setRenewalPeriod] = useState(0);
  const [renewalPeriodType, setRenewalPeriodType] = useState('');
  const [nextRenewalDate, setNextRenewalDate] = useState('');
  const [reportLimit, setReportLimit] = useState(-1);
  const [reportsRemaining, setReportsRemaining] = useState(-1);
  const [subscriptionState, setSubscriptionState] = useState('');
  const [planName, setPlanName] = useState('');

  // *****************************************************
  // Flags and calculated values
  // *****************************************************

  const [isAnnualSubscription, setIsAnnualSubscription] = useState(false);
  const [isCancelledSubscription, setIsCancelledSubscription] = useState(false);
  const [isExpiredSubscription, setIsExpiredSubscription] = useState(false);
  const [isSuspendedSubscription, setIsSuspendedSubscription] = useState(false);
  const [isLimitedUser, setIsLimitedUser] = useState(false);
  const [isStaff, setIsStaff] = useState(false);
  const [isUnlimitedUser, setIsUnlimitedUser] = useState(false);
  const [reportsRunPercentage, setReportsRunPercentage] = useState(0);
  const [reportsRun, setReportsRun] = useState(0);

  useEffect(() => {
    if (account) {
      const accountInfo = account.account;

      setAccountLoaded(true);

      setReportLimit(accountInfo.subscription_info.monthly_report_limit);
      setReportsRemaining(
        accountInfo.subscription_info.monthly_reports_remaining
      );
      setNextRenewalDate(accountInfo.subscription_info.normalize_date);
      setPlanAmount(
        parseFloat(accountInfo.subscription_info.money_amount.replace('$', ''))
      );
      setRenewalPeriod(accountInfo.subscription_info.renewal_period);
      setRenewalPeriodType(accountInfo.subscription_info.renewal_period_type);
      setSubscriptionState(accountInfo.subscription_info.subscription_state);
      setIsStaff(
        accountInfo.subscription_info.subscription_plan_internal_name ===
          'internal_staff'
      );
      setPlanName(accountInfo.subscription_info?.subscription_plan_unique_name);
    }
  }, [account]);

  useEffect(() => {
    setIsLimitedUser(reportLimit !== -1);
    setIsUnlimitedUser(reportLimit === -1);
    setReportsRun(reportLimit - reportsRemaining);
  }, [reportLimit, reportsRemaining]);

  useEffect(() => {
    if (reportLimit === -1) return;

    let progress = (reportsRun / reportLimit) * 100;

    if (progress > 100) progress = 100;

    setReportsRunPercentage(progress);
  }, [reportLimit, reportsRun]);

  useEffect(() => {
    if (!planAmount || !renewalPeriod) return;

    setPlanMonthlyCost(planAmount / renewalPeriod);
  }, [planAmount, renewalPeriod]);

  useEffect(() => {
    setIsAnnualSubscription(renewalPeriod === 12);
  }, [renewalPeriod]);

  useEffect(() => {
    setIsCancelledSubscription(
      subscriptionState?.toLowerCase() === 'cancelled'
    );
    setIsExpiredSubscription(subscriptionState?.toLowerCase() === 'expired');
    setIsSuspendedSubscription(
      subscriptionState?.toLowerCase() === 'suspended'
    );
  }, [subscriptionState]);

  return {
    accountLoaded,
    isAnnualSubscription,
    isCancelledSubscription,
    isExpiredSubscription,
    isSuspendedSubscription,
    isLimitedUser,
    isUnlimitedUser,
    isStaff,
    planAmount,
    planMonthlyCost,
    renewalPeriod,
    renewalPeriodType,
    nextRenewalDate,
    reportLimit,
    reportsRun,
    reportsRunPercentage,
    subscriptionState,
    isLoading,
    planName,
  };
}
