import * as yup from 'yup';

const PHONE_VALIDATION_REGEX = /^(\d{1} )?(\(\d{3}\)) (\d{3})-(\d{4})$/;
const ONE_BILLION_MAX_VALUE_TEST = function (v: string | undefined) {
  const strippedValue = v?.replace(/[$,]/g, '');

  return strippedValue ? parseInt(strippedValue) < 1000000000 : true;
};
const ONE_MINIMUM_VALUE_TEST = function (v: string | undefined) {
  const strippedValue = v?.replace(/[$,]/g, '');

  return strippedValue ? parseInt(strippedValue) > 0 : true;
};

export const fullNameSchema = yup
  .string()
  .required('Please enter a full name')
  .matches(/^[a-zA-Z\u00C0-\u017F\s]+$/, 'Name can only contain letters.')
  .test('full-name-validation', 'Please enter a full name', (value) => {
    return value.trim().split(' ').length > 1;
  });

export const astrologyFormSchema = yup.object({
  name: fullNameSchema,
  dob: yup
    .object()
    .required('Date of birth is required')
    .test('dob-validation', 'Please enter a valid date of birth', (value) => {
      return value && value <= new Date();
    }),
});

export const ascendantFormSchema = yup.object({
  birthHour: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required('Birth Hour is required')
    .min(0, 'Birth Hour must be between 00 and 23')
    .max(23, 'Birth Hour must be between 00 and 23'),
  birthMinute: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required('Birth Minute is required')
    .min(0, 'Birth Minute must be between 0 and 59')
    .max(59, 'Birth Minute must be between 0 and 59'),
  birthLocation: yup
    .string()
    .required('Birth Location is required')
    .matches(
      /^[a-zA-Z\u00C0-\u017F\s,]+$/,
      'City, Country can only contain letters and commas.'
    )
    .test(
      'location-validation',
      'Please enter a valid location (e.g. New York, US)',
      (value) => {
        return value.split(',').filter((v) => v.trim()).length === 2;
      }
    ),
});

export const signUpSchema = yup.object().shape({
  first_name: yup.string().trim().required(),
  last_name: yup.string().trim().required(),
  address: yup
    .string()
    .matches(/^[a-zA-Z0-9 ]*$/)
    .required(),
  apt: yup.string(),
  city: yup
    .string()
    .matches(/^[a-zA-Z ]*$/)
    .required(),
  state: yup.string().required('State required'),
  zip: yup
    .string()
    .matches(/^\d{5}$/)
    .required(),
  tokenized_ssn: yup
    .string()
    .matches(/^\d{9}$/)
    .required(),
  tokenized_ssn_confirmation: yup
    .string()
    .test('ssn-match', 'SSN must match', function (v) {
      return this.parent.tokenized_ssn === v;
    })
    .required('Must match the social security number'),
  phone_number: yup
    .string()
    .matches(PHONE_VALIDATION_REGEX, 'Please enter a valid phone number.')
    .required(),
  tokenized_dob: yup.string().required('Please submit a valid date'),
  terms_and_conditions: yup.boolean().oneOf([true]).required(),
});

export const scoreSimulatorSchema = yup.object().shape({
  PayOffAllCreditCards: yup.boolean().oneOf([true, false]).required(),
  CloseOldestCreditCard: yup.boolean().oneOf([true, false]).required(),
  IncreaseCreditBalance: yup
    .string()
    .test(
      'dollar-less-than-one-billion',
      'Sorry! Maximum value allowed is $999,999,999',
      ONE_BILLION_MAX_VALUE_TEST
    )
    .test(
      'dollar-less-than-one',
      'Sorry! Minimum value allowed is $1',
      ONE_MINIMUM_VALUE_TEST
    ),
  IncreaseCreditCardLimit: yup
    .string()
    .test(
      'dollar-less-than-one-billion',
      'Sorry! Maximum value allowed is $999,999,999',
      ONE_BILLION_MAX_VALUE_TEST
    )
    .test(
      'dollar-less-than-one',
      'Sorry! Minimum value allowed is $1',
      ONE_MINIMUM_VALUE_TEST
    ),
  DecreaseCreditBalance: yup
    .string()
    .test(
      'dollar-less-than-one-billion',
      'Sorry! Maximum value allowed is $999,999,999',
      ONE_BILLION_MAX_VALUE_TEST
    )
    .test(
      'dollar-less-than-one',
      'Sorry! Minimum value allowed is $1',
      ONE_MINIMUM_VALUE_TEST
    ),
  OnTimePayment: yup.string(),
  AllAccountsPastDue: yup.string(),
  OneAccountPastDue: yup.string(),
  ObtainCreditCard: yup
    .string()
    .test(
      'dollar-less-than-one-billion',
      'Sorry! Maximum value allowed is $999,999,999',
      ONE_BILLION_MAX_VALUE_TEST
    )
    .test(
      'dollar-less-than-one',
      'Sorry! Minimum value allowed is $1',
      ONE_MINIMUM_VALUE_TEST
    ),
  TransferCreditBalances: yup
    .string()
    .test(
      'dollar-less-than-one-billion',
      'Sorry! Maximum value allowed is $999,999,999',
      ONE_BILLION_MAX_VALUE_TEST
    )
    .test(
      'dollar-less-than-one',
      'Sorry! Minimum value allowed is $1',
      ONE_MINIMUM_VALUE_TEST
    ),
  ObtainCreditCardAsAuthorizedUser: yup
    .string()
    .test(
      'dollar-less-than-one-billion',
      'Sorry! Maximum value allowed is $999,999,999',
      ONE_BILLION_MAX_VALUE_TEST
    )
    .test(
      'dollar-less-than-one',
      'Sorry! Minimum value allowed is $1',
      ONE_MINIMUM_VALUE_TEST
    ),
  ApplyForCreditCard: yup.string(),
  ObtainAutoLoan: yup
    .string()
    .test(
      'dollar-less-than-one-billion',
      'Sorry! Maximum value allowed is $999,999,999',
      ONE_BILLION_MAX_VALUE_TEST
    )
    .test(
      'dollar-less-than-one',
      'Sorry! Minimum value allowed is $1',
      ONE_MINIMUM_VALUE_TEST
    ),
  ObtainPersonalLoan: yup
    .string()
    .test(
      'dollar-less-than-one-billion',
      'Sorry! Maximum value allowed is $999,999,999',
      ONE_BILLION_MAX_VALUE_TEST
    )
    .test(
      'dollar-less-than-one',
      'Sorry! Minimum value allowed is $1',
      ONE_MINIMUM_VALUE_TEST
    ),
  ObtainMortgage: yup
    .string()
    .test(
      'dollar-less-than-one-billion',
      'Sorry! Maximum value allowed is $999,999,999',
      ONE_BILLION_MAX_VALUE_TEST
    )
    .test(
      'dollar-less-than-one',
      'Sorry! Minimum value allowed is $1',
      ONE_MINIMUM_VALUE_TEST
    ),
});
