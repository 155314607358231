import { constants } from 'appConstants';

export const checkPlanVariations = (planName: string) => {
  return {
    isInternationalVinTest:
      constants.internationalPlanNames?.vinPlan.includes(planName),
    isInInternationalLanguageTest:
      constants.internationalPlanNames?.languagePlan.includes(planName),
    isInSingleReportVariation: constants.variationPlanNames?.includes(planName),
  };
};
