import type { propertiesTypes, serializedTypes } from '@ltvco/refresh-lib/v1';

export const choosePartnerValuesBySourceName = (
  property: serializedTypes.SerializedProperty
) => {
  const partnerName = 'bk';
  const partnerValues = property?.market?.filter(
    (partner) => partner?.source === partnerName
  );
  return partnerValues?.length > 0 ? partnerValues : [];
};

export const groupAvmsBySource = (
  marketValues: propertiesTypes.MarketValues
) => {
  const groupedData = marketValues.reduce(
    (
      result: { [key: string]: propertiesTypes.MarketValues },
      avm: propertiesTypes.MarketValues[number]
    ) => {
      if (!avm.source) return result;

      if (!result[avm.source]) {
        result[avm.source] = [];
      }

      result[avm.source].push(avm);

      return result;
    },
    {}
  );

  const arrayFormat: propertiesTypes.MarketValues[] =
    Object.values(groupedData);

  return arrayFormat?.length > 0 ? arrayFormat : [];
};

export const getPropertyDetailsCount = (
  property: serializedTypes.SerializedProperty
) => {
  const hasLotDetails = property?.lotAndBuildingDetails?.lotDetails?.hasData;
  const lotAndBuildingDetailsCount = hasLotDetails ? 1 : 0;

  const locationDataCount = property?.locationOfProperty?.length ? 1 : 0;

  const homeownerAssociationCount = property?.hoas?.length ? 1 : 0;

  const propertyAssessorCount = () => {
    const hasCountyAssessorRecordData =
      property?.parcel_address?.full ||
      property?.owner_occupied ||
      property?.owner_vesting;

    return hasCountyAssessorRecordData ? 1 : 0;
  };

  return (
    lotAndBuildingDetailsCount +
    locationDataCount +
    homeownerAssociationCount +
    propertyAssessorCount()
  );
};

export const getPropertyValuesCount = (
  property: serializedTypes.SerializedProperty
) => {
  const partnerValuesCount = choosePartnerValuesBySourceName(property).length;
  const propertyValuationCount = partnerValuesCount ? 1 : 0;

  const propertyTaxCount = Number(Boolean(property?.taxes));

  const propertyAssessmentCount = Number(
    Boolean(property?.assessments?.length)
  );

  return propertyValuationCount + propertyTaxCount + propertyAssessmentCount;
};
