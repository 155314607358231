import { familySearchAddressParser } from '@ltvco/refresh-lib/fmt';
import type { serializedTypes, commonTypes } from '@ltvco/refresh-lib/v1';

export const formatFamilyTreeDataFromAncestorReport = (
  person: serializedTypes.SerializedPerson,
  permalink: string,
  backupAddress?: string
) => {
  const names = person.identity.names?.[0]?.parsed;
  const dob = person.identity.dobs?.[0]?.date?.parsed;
  const dod = person.identity.dods?.[0]?.date?.parsed;
  const familySearchId = person.identity.fs_ids?.[0];
  const place = familySearchAddressParser(backupAddress);
  let profile_picture = null;

  if ('images' in person) profile_picture = person.images?.[0]?.url;

  return {
    first_name: names?.first,
    middle_name: names?.middle,
    last_name: names?.last,
    dob_month: dob?.month,
    dob_year: dob?.year,
    tokenized_dob_day: dob?.day,
    dod_month: dod?.month,
    dod_year: dod?.year,
    tokenized_dod_day: dod?.day,
    relationship: null,
    preparedFrom: '',
    profile_picture,
    ancestor_report: {
      family_search_id: familySearchId,
      permalink,
      givenName: [names?.first, names?.middle].filter(Boolean).join(' '),
      surname: names?.last,
      tokenized_dob_day: dob?.day,
      dob_month: dob?.month,
      dob_year: dob?.year,
      tokenized_dod_day: dod?.day,
      dod_month: dod?.month,
      dod_year: dod?.year,
      birth_place: [place.city, place.state, place.country]
        .filter(Boolean)
        .join(', '),
      place,
    },
  };
};

const normalizeNameOnCapitalization = (name: string | null | undefined) => {
  if (!name) return null;
  if (name !== name?.toUpperCase()) return name;

  return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
};

export const formatFamilyTreeDataFromPersonReport = (
  person: serializedTypes.SerializedPerson | serializedTypes.Connection,
  permalink: string | null,
  bvid: string | null,
  address?:
    | string
    | null
    | { city: string | null; state: string | null; country: string | null }
) => {
  const names = person.identity.names?.[0]?.parsed;
  const dob = person.identity.dobs?.[0]?.date?.parsed;
  const dod = person.identity.dods?.[0]?.date?.parsed;
  let profile_picture = null;

  if ('images' in person) profile_picture = person.images?.[0]?.url;

  const firstName = normalizeNameOnCapitalization(names?.first);
  const middleName = normalizeNameOnCapitalization(names?.middle);
  const lastName = normalizeNameOnCapitalization(names?.last);

  return {
    first_name: firstName,
    middle_name: middleName,
    last_name: lastName,
    dob_month: dob?.month,
    dob_year: dob?.year,
    tokenized_dob_day: dob?.day,
    dod_month: dod?.month,
    dod_year: dod?.year,
    tokenized_dod_day: dod?.day,
    relationship: null,
    preparedFrom: '',
    placeholder: false,
    profile_picture,
    person_report_params: {
      bvid: bvid || null,
      relatives_expanded: false,
      permalink,
      place: address,
    },
  };
};

export const formatFamilyTreeDataFromObituaryReport = (
  obituaryId: string,
  name: string,
  dob: commonTypes.Date,
  dod: commonTypes.Date,
  recentCity: string,
  recentRegion: string,
  permalink: string | null,
  photoUrl: string | null
) => {
  const nameParts = name.trim().split(' ');
  const first_name = nameParts[0] || null;
  const middle_name = nameParts.length === 3 ? nameParts[1] : null;
  const last_name =
    nameParts.length === 3 ? nameParts[2] : nameParts[1] || null;
  return {
    first_name,
    middle_name,
    last_name,
    dob_month: dob?.parsed?.month,
    dob_year: dob?.parsed?.year,
    tokenized_dob_day: dob?.parsed?.day,
    dod_month: dod?.parsed?.month,
    dod_year: dod?.parsed?.year,
    tokenized_dod_day: dod?.parsed?.day,
    relationship: null,
    preparedFrom: '',
    placeholder: false,
    profile_picture: photoUrl,
    obituary_report_params: {
      obituary_id: obituaryId,
      permalink: permalink,
      place: {
        city: recentCity,
        state: recentRegion,
      },
    },
  };
};
