import { ReportMonitor } from '@ltvco/refresh-lib/v1';

export const getClaimedReports = (
  monitorReports: ReportMonitor[],
  target_report_type: ReportMonitor['report_type']
) => {
  if (!monitorReports.length) return [];

  const claimedReports = monitorReports
    .filter(
      (report) =>
        report.report_type === target_report_type && Boolean(report.is_claimed)
    )
    .slice(0, 10);

  return claimedReports;
};
