import { constants } from '../../appConstants';
const contactProLink = 'https://www.beenverified.com/rf/search/contact';
const unclaimedMoneyLink =
  'https://www.beenverified.com/rf/dashboard/unclaimed-money';
const memberPerksLink = 'https://www.beenverified.com/rf/dashboard/rewards';
const requestApiAccessLink =
  'https://docs.google.com/forms/d/e/1FAIpQLScugCNnjhri8oJkTf6SqaPigmlWRviTcVewj55RsyhS-l3Pqw/viewform';
const needMoreReportsLink =
  'https://docs.google.com/forms/d/e/1FAIpQLSdsuNU5p7oD3CX3VvEMrBP1kvG3AodnhSvDytnb36rDVpcpxg/viewform';
const testimonialsLink = 'https://www.beenverified.com/reviews/';
const pressLink = 'https://www.beenverified.com/press/';
const careersLink = 'https://www.ltvco.com/careers/';
const upgradePlanLink = 'https://www.beenverified.com/rf/upgrade/plan';

export const generateFooterLinks = ({
  contactUsHandler,
  isUpgradeFormVariation,
  isInternationalTestUser,
}: {
  contactUsHandler: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  isUpgradeFormVariation?: boolean;
  isInternationalTestUser?: boolean;
}) => {
  const {
    links: { affiliateLink, aboutLink, doDontsLink, FaqLink, supportLink },
  } = constants;

  const commonSections = [
    {
      title: 'SUPPORT',
      links: [
        { text: 'Customer Care', href: supportLink },
        { text: 'Contact Us', href: '#', onClick: contactUsHandler },
        { text: "Do's & Dont's", href: doDontsLink },
        { text: "FAQ's", href: FaqLink },
      ],
    },
    {
      title: 'COMPANY',
      links: [
        { text: 'About BeenVerified', href: aboutLink },
        { text: 'Testimonials', href: testimonialsLink },
        { text: 'Press', href: pressLink },
        { text: 'Careers', href: careersLink },
      ],
    },
  ];

  if (isInternationalTestUser) {
    return [
      {
        title: 'RESOURCES',
        links: [{ text: 'Affiliates', href: affiliateLink }],
      },
      ...commonSections,
    ];
  }

  return [
    {
      title: 'MORE SEARCHES',
      links: [
        { text: 'Contact Pro', href: contactProLink },
        { text: 'Unclaimed Money', href: unclaimedMoneyLink },
      ],
    },
    {
      title: 'RESOURCES',
      links: [
        { text: 'Affiliates', href: affiliateLink },
        { text: 'Member Perks', href: memberPerksLink },
        { text: 'Request API Access', href: requestApiAccessLink },
        {
          text: 'Need More Reports?',
          href: isUpgradeFormVariation ? upgradePlanLink : needMoreReportsLink,
        },
      ],
    },
    ...commonSections,
  ];
};
