import { DateTime } from 'luxon';

export const isLessThanOneDayAgo = (
  dateString: string | undefined
): boolean => {
  if (!dateString) return false;

  const created = DateTime.fromISO(dateString);
  const now = DateTime.now().setZone('America/Chicago');
  const diffInHours = now.diff(created, 'hours').hours;

  return diffInHours < 24; // Less than 24 hours
};
